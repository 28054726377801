<template>
  <div>
    <el-container>
      <div v-if="userDeptType === 'all'" class="UserDeptMultipleTree">
        <tree-layout ref="treeLayout" treeTitle="组织架构" :treeLoading="treeLoading" :tree-options="treeOptions"
                     @tree-node-click="getNodeClick" @include-down="includeDownAll" style="margin-right:5px;">
        </tree-layout>
      </div>
      <div v-else class="UserDeptMultipleTree">
        <CommonTree :treeData="treeData" :defaultProps="defaultProps" :searchTitle="searchTitle" treeTitle="组织架构" class="userMultipTree"
                    :isShowdig="false" :showCheckbox="false" :popover-show="false"
                    :defaultCheckedKeys="defaultCheckedKeys"
                    @getNodeClick="getNodeClick" @include-down="includeDown"/>
      </div>
      <el-container>
        <el-main style="width: 50%">
          <head-layout :head-title="$t('cip.plat.sys.user.title.indexHeadTitle')">
          </head-layout>
          <grid-head-layout ref="searchFrom" :head-title="$t('cip.plat.sys.user.title.indexHeadTitle')"
                            :search-columns="searchColumns" v-model="searchForm" @grid-head-search="searchChange"
                            @grid-head-empty="searchReset">
          </grid-head-layout>
          <el-table
            border
            v-loading="tableLoading"
            :data="tableData"
            stripe
            ref="multipleTable"
            @selection-change="selectionChange"
            :height="510"
            rowKey="id"
          >
            <el-table-column reserve-selection type="selection" width="55"></el-table-column>
            <el-table-column :key="index" v-for="(item,index) in tableOptions.column"  :width="item.width"
                             :show-overflow-tooltip="item.overHidden" :prop="item.prop" :label="item.label"
                            >
              <template slot-scope="{row,$index}">
                <span v-html="item.formatter ? item.formatter(row, $index) : row[item.prop]"></span>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            class="margin-t-20"
            background
            @size-change="onLoad"
            @current-change="onLoad"
            :current-page.sync="page.currentPage"
            :page-sizes="[10, 20, 30, 40,50,100]"
            :page-size.sync="page.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="page.total">
          </el-pagination>
        </el-main>
        <el-main style="width: 50%">
          <head-layout head-title="已选用户" class="margin-b-14"></head-layout>
          <el-table
            border
            :data="selectData"
            stripe
            :height="600"
            rowKey="id">
            <el-table-column :key="index" v-for="(item,index) in tableOptions.column" :width="item.width"
                             :show-overflow-tooltip="item.overHidden" :prop="item.prop" :label="item.label">
              <template slot-scope="{row,$index}">
                <span v-html="item.formatter ? item.formatter(row, $index) : row[item.prop]"></span>
              </template>
            </el-table-column>
            <el-table-column label="操作" fixed="right">
              <template slot-scope="{$index,row}">
                <el-button
                  size="mini"
                  type="danger"
                  @click="handleDelete($index,row)">删除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-main>
      </el-container>
    </el-container>
    <div style="display: flex;justify-content: flex-end;border-top: 1px solid #CCCCCC;padding-top: 30px">
      <el-button :loading="loading" :disabled="loading" size="mini" @click="closeDialog">取消</el-button>
      <el-button :loading="loading" :disabled="loading" size="mini" type="primary" @click="confirm">确认</el-button>
    </div>
  </div>
</template>

<script>
import {PAGE_CONSTANT} from "@/util/pageConstantEnum";
import {roleAllocationByList} from "@/api/system/user";
import {selectChildren, getDeptLazyTreeAll, getDeptTree} from "@/api/system/dept";
import TreeLayout from "@/views/components/layout/tree-layout";
import HeadLayout from "@/views/components/layout/head-layout"
import GridLayout from "@/views/components/layout/grid-layout";
import CommonTree from "@/views/components/com_tree/index"
import {HD_SOURCE_TYPE} from "@/api/riskitemView/checklist";
import {mapGetters} from "vuex";
import {dateFormat} from "@/util/date";

export default {
  name: "user",
  components: {
    TreeLayout,
    HeadLayout,
    GridLayout,
    CommonTree
  },
  props: {
    closeCallBack:Boolean,
    dataCallBack:Function,
    deptCategory: [],
    userDeptType: {
      type: String,
      default: ""
    },
    publicParams: {
      type: Object,
      default: {}
    },
    treeParams: {
      type: Object,
      default: {}
    },
    //设置默认选中
    checkedData:{
      type:Array,
      default:[]
    }
  },
  data() {
    return {
      loading:false,
      searchColumns: [
        {
          label: "",
          prop: "account",
          span: 6,
          placeholder: this.$t("cip.cmn.rule.inputWarning") + this.$t("cip.plat.sys.user.field.account"),
        },
        {
          label: "",
          prop: "realName",
          span: 6,
          placeholder: this.$t("cip.cmn.rule.inputWarning") + this.$t("cip.plat.sys.user.field.realName"),
        },
        {
          label: "用户类型",
          prop: "personnelType",
          type: "select",
          props: {
            label: "dictValue",
            value: "dictKey",
          },
          dataType: 'string',
          dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=personnel_type",
          span: 6,
          placeholder: "请选择用户类型",
        },
        // {
        //   label: "岗位类型",
        //   prop: "postId",
        //   type: "select",
        //   props: {
        //     label: "postName",
        //     value: "postCode",
        //   },
        //   dicData: [],
        //   dataType: 'string',
        //   dicUrl: "/api/sinoma-system/post/select?tenantId=000000",
        //   span: 6,
        //   placeholder: "请选择岗位类型",
        // },
      ],
      personnel_type: [],
      selectData: [],
      treeLoading: true,
      searchTitle: 'title',
      defaultProps: {
        label: "title",
        value: "key"
      },
      searchForm: {},
      gridRowBtn: [
        {
          label: this.$t('cip.cmn.btn.defBtn'),
          emit: "role-defBtn",
          type: "text",
          icon: ""
        }
      ],
      defaultCheckedKeys: [],
      tableData: [],
      tableLoading: true,
      selectionList: [],
      query: {},
      page: {
        pageSize: PAGE_CONSTANT.DEFAULT_PAGE_SIZE,
        currentPage: 1,
        total: 0
      },
      props: {
        label: "title",
        value: "key"
      },
      treeDeptId: '',
      treeData: [],
      tableOptions: {
        customRowAddBtn: false,
        menu: false,
        column: [
          {
            label: "用户账号",
            prop: "account",
            align: 'left',
            width: 150,
          },
          {
            label: this.$t("cip.plat.sys.user.field.realName"),
            prop: "realName",
            width: 100,
            align: 'center',
            overHidden: true,
          },
          {
            label: this.$t("cip.plat.sys.user.field.deptName"),
            prop: "deptName",
            align: 'left',
            overHidden: true,
          },
          {
            label: "用户类型",
            prop: "personnelType",
            type: "select",
            width: 200,
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            align: 'left',
            dataType: 'string',
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=personnel_type"
          },
        ],
      },
    };
  },
  watch: {
    defaultCheckedKeys: {
      handler: function (newVal) {
        if (newVal) {
          this.$nextTick(() => {
            document.querySelector(".UserDeptMultipleTree .el-tree-node__content").click();
          });
        }
      },
    },
  },
  computed: {
    treeOptions() {
      let that = this;
      return {
        defaultExpandAll: false,
        nodeKey: 'id',
        lazy: true,
        treeLoad: function (node, resolve) {
          that.treeLoading = true;
          const parentId = (node.level === 0) ? 0 : node.data.id;
          getDeptLazyTreeAll(parentId).then(res => {
            resolve(res.data.data.map(item => {
              that.treeLoading = false;
              return {
                ...item,
                leaf: !item.hasChildren
              }
            }))
          });
        },
        menu: false,
        addBtn: false,
        // size: 'mini',
        props: {
          labelText: this.$t("cip.plat.sys.user.field.title"),
          label: 'title',
          value: 'value',
          children: 'children'
        }
      }
    },
    ...mapGetters(["userInfo"]),
  },
  mounted() {
    this.getCode()
    if (this.userDeptType != 'all') {
      if (this.deptCategory !== undefined || this.deptCategory) {
        this.getTreeData(this.deptCategory.join(','));
      } else {
        this.getTreeData('');
      }
    } else {
      this.onLoad(this.page)
    }
  },
  methods: {
    itemObject(item) {
      let age = this.getAge(item.birthday)
      if ((item.sex == 1 && age >= 50) || age >= 60 || age < 18) {
        return {color: "red"};
      } else {
        return {color: "black"};
      }
    },
    async getCode() {
      this.personnel_type = await HD_SOURCE_TYPE('personnel_type').then(res => res.data.data)
    },
    //请选择人员
    confirm() {
      this.loading=true
      if(this.closeCallBack){
        this.$emit('select-all-data', {
          selectAllData: this.selectData,
          treeDeptId: this.treeDeptId,
          treeDeptName: this.treeDeptName,
          loading:()=>{
            this.loading=false
          },
          done: ()=>{
            this.closeDialog();
          }
        });
      }else {
        this.$emit('select-all-data', {
          selectAllData: this.selectData,
          treeDeptId: this.treeDeptId,
          treeDeptName: this.treeDeptName,
        });
        this.closeDialog();
      }
    },
    //清楚人员
    closeDialog() {
      this.loading=false
      this.$emit('closeDia');
    },
    includeDownAll(e) {
      this.page.currentPage = 1;
      this.query.isInclude = e;
      this.onLoad(this.page);
    },
    getTreeData(deptCategory) {
      // selectChildren(deptCategory).then((res) => {
      //   this.treeData = res.data.data
      //   this.$nextTick(() => {
      //     this.defaultCheckedKeys.push(this.treeData[0].id);
      //   })
      // })
      getDeptTree('', '', this.userInfo.dept_id, this.treeParams).then((res) => {
        this.treeData = res.data.data;
        this.$nextTick(() => {
          this.defaultCheckedKeys.push(this.treeData[0].id);
        })
      })
    },
    getNodeClick(data) {
      this.treeDeptId = data.id;
      this.treeDeptName = data.deptName;
      this.page.currentPage = 1;
      this.onLoad(this.page);
    },
    includeDown(e) {
      this.page.currentPage = 1;
      this.query.isInclude = e;
      this.onLoad(this.page);
    },
    searchReset(data) {
      this.query = {};
      this.onLoad(this.page);
    },
    searchChange(params, done) {
      if (params.account == '') {
        delete params.account
      }
      if (params.realName == '') {
        delete params.realName
      }

      if (params.userTypeName == '') {
        delete params.userTypeName
      }
      delete params.$userTypeName
      this.query = params;
      this.page.currentPage = 1;
      this.onLoad(this.page, params);
    },
    handleDelete(index, row) {
      this.selectData.splice(index, 1);
      this.$refs.multipleTable.toggleRowSelection(row, false);
    },
    selectionChange(selection, row) {
      selection.forEach(item => {
        item.treeDeptId = this.treeDeptId;
        item.treeDeptName = this.treeDeptName;
      })
      this.selectData = selection
    },
    // 清除左侧已勾选数据，触发selectionChange方法，使已选用户也清空
    selectionClear() {
      this.$refs.multipleTable.clearSelection();
    },
    onLoad() {
      this.tableLoading = true;
      this.query.isEnabled = 0;
      if (this.userDeptType === "all") {
        this.query.deptName = "all";
      }
      if (this.query.personnelType) {
        this.query.personnelTypes = this.query.personnelType.join(',')
      }
      roleAllocationByList(this.page.currentPage, this.page.pageSize, Object.assign({...this.publicParams, ...this.query}), this.treeDeptId).then(res => {
        this.tableLoading = false;
        const data = res.data.data;
        this.page.total = data.total;
        this.tableData =typeof this.dataCallBack == 'function' ?this.dataCallBack(data.records) : data.records
        let checkedData=this.checkedData.map(item=>item.id)
        this.$nextTick(()=>{
            this.tableData.map((item,index)=>{
              if(checkedData.includes(item.id)){
                this.$refs.multipleTable.toggleRowSelection(item)
              }
            })
        })
      });

    },
  }
};
</script>

<style lang="scss" scoped>
.el-scrollbar {
  height: 100%;
}

.box .el-scrollbar__wrap {
  overflow: scroll;
}

.margin-t-20 {
  margin-top: 20px;
}

.margin-b-14 {
  margin-bottom: 14px;
}

.dialogBtn {
  display: flex;
  align-items: center;
  justify-content: center;
}

::v-deep .UserDeptMultipleTree .el-tree {
  // height: calc(100vh - 435px) !important;
  height: 60vh !important;
  max-height: 60vh !important;
}

::v-deep .userMultipTree .header {
  height: 38px !important;
  padding: 0;
  text-indent: 12px;
}

::v-deep .userMultipTree .header-row {
  line-height: 37px !important;
}

::v-deep .el-dialog {
  margin-bottom: 0px !important;
}
</style>
