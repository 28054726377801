import {
  getWtTicketDetail,
  myWtTicketSubmit,
  submitWtTicket,
  submitSecurityDisclosure,
  submitSafetySupervision,
  submitAcceptanceConfirmation,
  notSubmittedForSignature,
  notSubmittedForSignatureSubmit
} from "@/api/workTicket";
import exForm from "@/views/plugin/workflow/mixins/ex-form";
import {detail as processDetail} from "@/api/plugin/workflow/process";
import {dictionaryBiz} from "@/api/reportTasks";

export default {
  mixins: [exForm],
  data() {
    return {
      ticketDetail: {},
      formProcess: {
        id: '',
        processId: '',
        data: {},
        // userDialogSkip: false
      },
    }
  },
  methods: {
    // 跳转指定锚点
    runJump(name) {
      if (document.querySelector('#' + name) == null) return
      document.querySelector('#' + name).scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "start"
      });
    },
    // 新增人员审核
    addExamPeople() {
      this.userOpentype = "examPeople";
      this.operatorSelect = true;
      this.userListShow = true;
      this.$nextTick(() => {
        this.$refs.userDeptMultipleDialog.selectionClear();
      });
      this.examPeopleList = [];
    },
    // 删除人员审核
    deleteExamPeople(item, index) {
      this.examPeopleList.splice(index, 1)
    },
    // 新增经理审核
    addExamManage() {
      this.userOpentype = "examManage";
      this.operatorSelect = true;
      this.userListShow = true;
      this.$nextTick(() => {
        this.$refs.userDeptMultipleDialog.selectionClear();
      });
      this.examManageList = [];
    },
    // 删除经理审核
    deleteExamManage(item, index) {
      this.examManageList.splice(index, 1)
    },
    // 提交
    async headRefer() {
      await this.headSave("processSubmission").then((saveData) => {
        console.log("this.businessStatus",this.businessStatus)
        if (saveData) {
          if (this.businessStatus == "PREPARE") {
            getWtTicketDetail(this.dataForm.ticketCode).then((res) => {
              this.ticketDetail = res.data.data
              if (this.ticketDetail.processInstanceId) {
                // this.$confirm(" 是否要提交", {
                //   confirmButtonText: "确认",
                //   cancelButtonText: "取消",
                //   type: "warning",
                // }).then(() => {
                this.$loading();
                processDetail({processInsId: this.ticketDetail.processInstanceId}).then((res) => {
                  this.reportExResubmit(res.data.data.process).then(res => {
                    // 关闭当前页面
                    this.$loading().close();
                    this.$router.$avueRouter.closeTag();
                    this.$router.back();
                    this.$message({
                      type: "success",
                      message: "提交成功",
                    });
                  });
                });
                // });
              } else {
                notSubmittedForSignature(this.ticketDetail.ticketCode).then((res) => {
                  console.log("this.businessStatus",this.businessStatus)
                  let userText = "";
                  if (res.data.data !== "complete") {
                    userText = res.data.data.toString() + "没有签署";
                  } else {
                    userText = "";
                  }
                  if (userText!="") {
                    this.$confirm(userText + " 是否要提交", {
                      confirmButtonText: "确认",
                      cancelButtonText: "取消",
                      type: "warning",
                    }).then(() => {
                      if (userText !== "") {
                        notSubmittedForSignatureSubmit(this.ticketDetail.ticketCode);
                      }
                      this.selectUser(this.ticketDetail, false)
                    });
                  } else {
                    this.selectUser(this.ticketDetail, false)
                  }
                });
              }
            })
          }
          if (this.businessStatus == "CONFIRMING") {
            // this.$confirm("提交后无法编辑，确认提交吗?", {
            //   confirmButtonText: this.$t("cip.cmn.btn.defBtn"),
            //   cancelButtonText: this.$t("cip.cmn.btn.celBtn"),
            //   type: "warning",
            // }).then(() => {
            this.headSave("submit");
            // });
          }
          if (this.businessStatus == "DISCLOSED") {
            submitSafetySupervision(this.dataForm.ticketCode).then((res) => {
              this.$message({
                type: "success",
                message: "提交成功",
              });
              this.headCancel();
            });
          }
          if (this.businessStatus == "SUPERVISE") {
            submitSecurityDisclosure(this.dataForm.ticketCode).then((res) => {
              this.$message({
                type: "success",
                message: "提交成功",
              });
              this.headCancel();
            });
          }
          if (this.businessStatus == "CHECKACCEPT") {
            submitAcceptanceConfirmation(this.dataForm.ticketCode).then((res) => {
              this.$message({
                type: "success",
                message: "提交成功",
              });
              this.headCancel();
            });
          }
        }
      });
    },
    selectUser(row, flag) {
      //修改草稿状态为待审批
      this.$loading();
      let data = {
        id: row.id,
        businessStatus: "APPROVING"
      }
      if (this.ticketDetail.processInstanceId) {
        myWtTicketSubmit(data).then(res => {
          // 关闭当前页面
          this.$loading().close();
          this.$router.$avueRouter.closeTag();
          this.$router.back();
          this.$message({
            type: "success",
            message: "提交成功",
          });
        })
      } else {
        this.formProcess.id = row.id
        this.formProcess.data = {...row}
        this.formProcess.deptId = this.userInfo.dept_id
        this.formProcess.processBusinessName = row.content
        // this.formProcess.userDialogSkip = false
        this.formProcess.weChatUrl = '/pages/news/jobticket2/ticketFlowWebview'
        dictionaryBiz("work_permit_type").then((res) => {
          let data1 = res.data.data;
          let filter = data1.filter(e => e.dictKey == row.ticketType);
          this.formProcess.taskName = filter[0].dictValue + "流程|" + row.content + "|" + row.ticketCode + "|" + this.userInfo.nick_name
          console.log(this.formProcess, 'this.formProcess')
          this.formProcess.typeName = '危险作业';
          //发起流程
          this.handleStartProcess6(true, flag).then(done => {
            // 更新业务数据流程实例id
            data.processInstanceId = done.data.data
            myWtTicketSubmit(data).then(res => {
              //搜索条件未传
              this.$loading().close();
              this.$router.$avueRouter.closeTag();
              this.$router.back();
              this.$message({
                type: "success",
                message: "提交成功",
              });
            })
          })
        });
      }
    },
    closeProcess() {
      this.$loading().close();
    },
    // 选择流程用户
    handleUserSelection(userIds) {
      this.formProcess.assignee = userIds;
      this.selectUser(this.ticketDetail, false)
    },
  }
}
