var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("head-layout", { attrs: { "head-title": "人员签字" } }),
      _c(
        "el-form",
        {
          staticStyle: { padding: "12px" },
          attrs: { model: _vm.securityData, "label-width": "100px" },
        },
        [
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { span: 24 } }, [
                _c(
                  "div",
                  { staticStyle: { display: "flex", "flex-wrap": "wrap" } },
                  [
                    _vm._l(_vm.securityData.receive, function (item, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticClass: "imgBox",
                          staticStyle: { margin: "0 12px 12px 0" },
                          on: {
                            mouseenter: function ($event) {
                              item.maskShow = true
                            },
                            mouseleave: function ($event) {
                              item.maskShow = false
                            },
                          },
                        },
                        [
                          _c("img", { attrs: { src: item.imgUrl } }),
                          item.maskShow
                            ? _c("div", { staticClass: "maskBox" }, [
                                _c("i", {
                                  staticClass: "el-icon-view",
                                  on: {
                                    click: function ($event) {
                                      return _vm.viewImg(item.imgUrl)
                                    },
                                  },
                                }),
                                ["edit", "add", "sign"].includes(
                                  _vm.securityType
                                ) && _vm.businessStatus != "APPROVING"
                                  ? _c("i", {
                                      staticClass: "el-icon-delete",
                                      on: {
                                        click: function ($event) {
                                          return _vm.deleteImg(
                                            item.id,
                                            "receive",
                                            index
                                          )
                                        },
                                      },
                                    })
                                  : _vm._e(),
                              ])
                            : _vm._e(),
                        ]
                      )
                    }),
                    ["edit", "add", "sign"].includes(_vm.securityType) &&
                    _vm.businessStatus != "APPROVING"
                      ? _c(
                          "div",
                          {
                            staticClass: "cardBorder",
                            on: {
                              click: function ($event) {
                                return _vm.openSignature("receive")
                              },
                            },
                          },
                          [_c("i", { staticClass: "el-icon-plus" })]
                        )
                      : _vm._e(),
                  ],
                  2
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm.signatureShow
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: "电子签名",
                visible: _vm.signatureShow,
                width: "1000px",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.signatureShow = $event
                },
              },
            },
            [_c("signature", { on: { getSignatureImg: _vm.getSignatureImg } })],
            1
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: { title: "预览签名", visible: _vm.imgShow, width: "1000px" },
          on: {
            "update:visible": function ($event) {
              _vm.imgShow = $event
            },
          },
        },
        [
          _c("img", {
            staticStyle: { width: "100%", height: "500px" },
            attrs: { src: _vm.imgSrc },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }